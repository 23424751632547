import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchIncidents,
  editIncident,
  selectIncident,
  deleteIncident,
} from "../../../../../Redux/actions/Incidents/fetchIncidentAction";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import {
  showDispatchModal,
  hideDispatchModal,
} from "../../../../../Redux/actions/UI/toggleDispatchModalAction";
import DataTable from "react-data-table-component";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import More from "../../../../../assets/more.svg";
import Edit from "../../../../../assets/edit.svg";
import Delete from "../../../../../assets/delete.svg";
import Dispatch from "../../../../../assets/dispatch.svg";
import orderBy from "lodash/orderBy";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import DispatchModal from "./Dispatch/DispatchModal";

class IncidentTable extends Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
      incidentId: "",
    };
  }

  componentDidMount = async () => {
    await this.props.fetchIncidents();
  };
  componentDidUpdate = async (prevProps) => {
    if (
      this.props.dispatchModal !== prevProps.dispatchModal ||
      this.props.modal !== prevProps.modal
    ) {
      await this.props.fetchIncidents();
      this.renderIncidents();
    }
  };

  handleChange = (event) => {
    this.setState({ filterText: event.target.value });
  };

  modalHandler() {
    if (this.props.modal) {
      return (
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal isOpen={this.props.modal} toggle={this.props.modal} centered size="sm">
            <MDBModalHeader>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.props.hideModal} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  if (this.selectedIncident !== null) {
                    this.props.deleteIncident(this.props.selectedIncident.id);
                    this.props.hideModal();
                  }
                }}
                size="sm"
              >
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }
  }

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field]) {
        return row[field];
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  actionOptions = (row) => {
    return (
      <>
        <Dropdown.Item
          onClick={() => {
            this.props.selectIncident(row.id);
            this.props.showDispatchModal();
          }}
        >
          <div className="table-dispatch">
            <img src={Dispatch} alt="" /> Dispatch
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => this.props.editIncident(row.id)}>
          <div className="table-edit">
            <img src={Edit} alt="" /> Edit
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            this.props.selectIncident(row.id);
            this.setState({ incidentId: row.id });
            this.props.showModal();
          }}
        >
          <div className="table-delete">
            <img src={Delete} alt="" /> Delete
          </div>
        </Dropdown.Item>
      </>
    );
  };

  renderIncidents() {
    if (this.props.incidents !== null && this.props.incidents.isLoading === false) {
      return this.props.incidents.incidents.incidents.map((incident) => {
        let index = incident.incidentTimeOfAnnouncement.split("T");
        return {
          id: incident.id,
          name: incident.name,
          emergency: incident.typeOfEmergency.toLowerCase().replace(/_/, " "),
          location: incident.location,
          date: index[0],
          status: incident.isActive === true ? "Active" : "InActive",
          edit: (
            <MDBBtn color="primary" size="sm" onClick={() => this.props.editIncident(incident.id)}>
              Edit
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              color="danger"
              size="sm"
              onClick={() => {
                this.props.selectIncident(incident.id);
                this.props.showModal();
              }}
            >
              Delete
            </MDBBtn>
          ),
          dispatch: (
            <MDBBtn
              color="primary"
              size="sm"
              onClick={() => {
                this.props.selectIncident(incident.id);
                this.props.showDispatchModal(incident.id);
              }}
            >
              Dispatch
            </MDBBtn>
          ),
        };
      });
    }
  }

  render() {
    let filteredData;
    const { filterText } = this.state;
    if (this.props.incidents !== null && this.props.incidents.isLoading === false) {
      filteredData = this.renderIncidents().filter(
        (item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase()) ||
          item.emergency.toLowerCase().includes(filterText.toLowerCase()) ||
          item.location.toLowerCase().includes(filterText.toLowerCase()) ||
          item.date.toLowerCase().includes(filterText.toLowerCase()) ||
          item.status.toLowerCase().includes(filterText.toLowerCase())
        //   item.id.toLowerCase().includes(filterText.toLowerCase())
      );
    }

    const columns = [
      {
        name: "Id",
        selector: "id",
        grow: 0,
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        grow: 1,
        sortable: true,
      },
      {
        name: "Date",
        selector: "date",
        grow: 1,
        sortable: true,
      },
      {
        name: "Location",
        selector: "location",
        grow: 1,
        sortable: true,
      },
      {
        name: "Emergency",
        selector: "emergency",
        grow: 1,
        sortable: true,
      },

      {
        name: "Status",
        selector: "status",
        grow: 0,
        sortable: true,
      },
      {
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-action-user-org">
              <img src={More} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="edit-table-wrapper">{this.actionOptions(row)}</Dropdown.Menu>
          </Dropdown>
        ),
        selector: "action",
        allowOverflow: true,
        button: true,
        right: true,
      },
    ];

    if (this.props.incidents !== null && this.props.incidents.isLoading === true) {
      return <Spinner />;
    } else {
      return (
        <>
          <div className="table-search-wrapper">
            <input
              className="table-search"
              placeholder="Search Incidents"
              value={this.filter}
              onChange={this.handleChange}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination={true}
            paginationPerPage={4}
            paginationRowsPerPageOptions={[4, 10, 15]}
            sortFunction={this.customSort}
          />
          <div>{this.modalHandler()}</div>
          <DispatchModal
            isOpen={this.props.dispatchModal}
            toggle={this.props.dispatchModal}
            isClosed={() => this.props.hideDispatchModal()}
            incident={this.props.selectedIncident}
          />
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    incidents: state.incidents,
    modal: state.toggleModal.show,
    dispatchModal: state.toggleDispatchModal.showDispatch,
    selectedIncident: state.selectIncident,
    deletedIncident: state.deleteIncident,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchIncidents,
      editIncident,
      selectIncident,
      deleteIncident,
      showModal,
      hideModal,
      showDispatchModal,
      hideDispatchModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentTable);
