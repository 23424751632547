import axios from 'axios'
import React, { Component } from 'react'
import environment from '../../environment'

class TweetsFeed extends Component {
  constructor() {
    super()
    this.state = {
      tweetResp: [],
    }
  }
  componentDidMount = () => {
    axios
      .get(`${environment.mainUrl}` + 'twitter/potentialIncident')
      .then((response) => this.setState({ tweetResp: response }))
    console.log('TWEET', this.state.tweetResp)
  }

  render() {
    return <div>Hello!</div>
  }
}

export default TweetsFeed
