import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  showMembers,
  showRegister,
  showIncidents,
  closeRegisterMember,
  clearCss,
  removeClearCss,
} from '../../Redux/actions/UI/sideMenuActions'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faPencilAlt,
  faLevelUpAlt,
  faUserFriends,
  faExclamationTriangle,
  faUsers,
  faHospital,
} from '@fortawesome/free-solid-svg-icons'

class Sidemenu extends React.Component {
  showRegisterMembers = false
  showMembers = false
  showIncidents = false

  registerMembers = () => {
    this.showMembers = false
    if (this.showRegisterMembers) {
      this.showRegisterMembers = false
    } else {
      this.showRegisterMembers = true
    }
  }

  adminDashboardClick = () => {
    this.props.closeRegisterMember()
    this.showRegisterMembers = false
    this.showMembers = false
  }

  adminAnalyticsClick = () => {
    this.props.closeRegisterMember()
    this.props.clearCss()
    this.showRegisterMembers = false
    this.showMembers = false
  }

  // Psap sidemenu options
  incidents = () => {
    this.showIncidents = false
    if (this.showIncidents) {
      this.showIncidents = false
    } else {
      this.showIncidents = true
    }
  }

  psapDashboardClick = () => {
    this.props.clearCss()
    this.props.sideMenu.showIncidents = false
  }

  psapAnalyticsClick = () => {
    this.props.clearCss()
    this.props.sideMenu.showIncidents = false
  }

  menuOptions = () => {
    let role
    if (
      this.props.security.user.user_roles &&
      this.props.security.user.user_roles[0] !== undefined &&
      this.props.security.user.user_roles[0] === 'HIGH_COMMANDER'
    ) {
      role = this.props.security.user.user_roles[0]
    } else if (
      this.props.security.user.user_roles &&
      this.props.security.user.user_roles[0] !== undefined &&
      this.props.security.user.user_roles[0] === 'HOSPITAL_COMMANDER'
    ) {
      role = this.props.security.user.user_roles[0]
    } else if (
      this.props.security.user.user_roles &&
      this.props.security.user.user_roles[0] !== undefined &&
      this.props.security.user.user_roles[0] === 'EMS'
    ) {
      if (
        this.props.security.user.incidentRole &&
        this.props.security.user.incidentRole !== undefined &&
        this.props.security.user.incidentRole === 'PSAP'
      ) {
        role = this.props.security.user.incidentRole
      } else if (
        this.props.security.user.incidentRole &&
        this.props.security.user.incidentRole !== undefined &&
        this.props.security.user.incidentRole === 'FIELD_COMMANDER'
      ) {
        role = this.props.security.user.incidentRole
      } else {
        role = this.props.security.user.user_roles[0]
      }
    }
    let menuLinks = null
    switch (role) {
      case 'HIGH_COMMANDER':
        menuLinks = (
          <React.Fragment>
            <ul className='sidemenu-ul'>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                }`}
                to='/Dashboard'
                activeClassName='activeMI'
                exact
              >
                <li
                  className='sidemenu-li'
                  id='sidemenu-dashboard'
                  onClick={this.adminDashboardClick}
                >
                  <FontAwesomeIcon
                    icon={faHome}
                    size='sm'
                    className='fontawesome-sidemenu'
                  />
                  Dashboard
                </li>
              </NavLink>
              <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                }`}
                to='/Patients'
                activeClassName='activeMI'
                exact
              >
                <li className='sidemenu-li'>
                  <FontAwesomeIcon
                    icon={faHospital}
                    size='sm'
                    className='fontawesome-sidemenu'
                  />
                  Patients
                </li>
              </NavLink>

              <li
                className='sidemenu-li'
                id='sidemenu-register-members'
                onClick={() => {
                  this.props.showRegister()
                }}
              >
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Organizations
              </li>
              <ul
                id='sidemenu-ul-register'
                style={{
                  height:
                    this.props.sideMenu.showRegister === true ? '90px' : '0vh',
                }}
              >
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                  }`}
                  to='/RegisterOrganization'
                  activeClassName='activeMI'
                  exact
                >
                  <li
                    className='sidemenu-li-sub'
                    id='sidemenu-regorgclick'
                    onClick={this.regOrgClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size='xs'
                      className='fontawesome-sidemenu-sub'
                    />
                    Register
                  </li>
                  <div id='borderRight'></div>
                </NavLink>
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                  }`}
                  to='/OrgMGT'
                  activeClassName='activeMI'
                  exact
                >
                  <li
                    className='sidemenu-li-sub'
                    id='sidemenu-showorgclick'
                    onClick={this.showOrgClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size='xs'
                      className='fontawesome-sidemenu-sub'
                    />
                    View
                  </li>
                  <div id='borderRight'></div>
                </NavLink>
                <div id='borderRight'></div>
              </ul>
              <li
                className='sidemenu-li'
                id='sidemenu-show-members'
                onClick={this.props.showMembers}
              >
                <FontAwesomeIcon
                  icon={faUserFriends}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Users
              </li>
              <ul
                id='sidemenu-ul-show'
                style={{
                  height:
                    this.props.sideMenu.showMembers === true ? '90px' : '0vh',
                }}
              >
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                  }`}
                  to='/RegisterUser'
                  activeClassName='activeMI'
                  exact
                >
                  <li
                    className='sidemenu-li-sub'
                    id='sidemenu-reguserclick'
                    onClick={this.regUserClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size='xs'
                      className='fontawesome-sidemenu-sub'
                    />
                    Register
                  </li>
                </NavLink>

                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                  }`}
                  to='/UsersMGT'
                  activeClassName='activeMI'
                  exact
                >
                  <li
                    className='sidemenu-li-sub'
                    id='sidemenu-showuserclick'
                    onClick={this.showUserClick}
                  >
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size='xs'
                      className='fontawesome-sidemenu-sub'
                    />
                    View
                  </li>
                  <div id='borderRight'></div>
                </NavLink>
              </ul>
              <li
                className='sidemenu-li'
                id='sidemenu-incidents'
                onClick={this.props.showIncidents}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Incidents
              </li>
              <ul
                id='sidemenu-ul-show'
                style={{
                  height:
                    this.props.sideMenu.showIncidents === true
                      ? '190px'
                      : '0vh',
                }}
              >
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                  }`}
                  to='/RegisterIncident'
                  activeClassName='activeMI'
                  exact
                >
                  <li className='sidemenu-li-sub'>
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size='xs'
                      className='fontawesome-sidemenu-sub'
                    />
                    Add New
                  </li>
                </NavLink>
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                  }`}
                  to='/IncidentMGT'
                  activeClassName='activeMI'
                  exact
                >
                  <li className='sidemenu-li-sub'>
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size='xs'
                      className='fontawesome-sidemenu-sub'
                    />
                    Show Incidents
                  </li>
                </NavLink>
                <NavLink
                  className={`sidemenu-nav  ${
                    this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
                  }`}
                  to='/TrainingPlatform'
                  activeClassName='activeMI'
                  exact
                >
                  <li className='sidemenu-li-sub'>
                    <FontAwesomeIcon
                      icon={faLevelUpAlt}
                      rotation={90}
                      size='xs'
                      className='fontawesome-sidemenu-sub'
                    />
                    Training Platform
                  </li>
                </NavLink>
              </ul>

              {/* <NavLink
                className={`sidemenu-nav  ${
                  this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
                }`}
                to="/Analytics"
                activeClassName="activeMI"
                exact
              >
                <li
                  className="sidemenu-li"
                  id="sidemenu-analytics"
                  onClick={this.adminAnalyticsClick}
                >
                  <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                  Incident Analytics
                </li>
              </NavLink> */}
            </ul>
          </React.Fragment>
        )
        return menuLinks
      case 'EMS':
        menuLinks = (
          <ul className='sidemenu-ul'>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/'
              activeClassName='activeMI'
              exact
            >
              <li
                className='sidemenu-li'
                id='sidemenu-dashboard'
                onClick={this.psapDashboardClick}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Dashboard
              </li>
            </NavLink>

            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Analytics"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-analytics" onClick={this.psapAnalyticsClick}>
                <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                Incident Analytics
              </li>
            </NavLink> */}
          </ul>
        )
        return menuLinks
      case 'PSAP':
        menuLinks = (
          <ul className='sidemenu-ul'>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/'
              activeClassName='activeMI'
              exact
            >
              <li
                className='sidemenu-li'
                id='sidemenu-dashboard'
                onClick={this.psapDashboardClick}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Dashboard
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/IncidentMGT'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li-sub'>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Incident
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/IncidentTeam'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li-sub'>
                <FontAwesomeIcon
                  icon={faUsers}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Team
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/HospitalCis'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li-sub'>
                <FontAwesomeIcon
                  icon={faHospital}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Hospitals
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/Patients'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li'>
                <FontAwesomeIcon
                  icon={faHospital}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Patients
              </li>
            </NavLink>
            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Analytics"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-analytics" onClick={this.psapAnalyticsClick}>
                <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                Incident Analytics
              </li>
            </NavLink> */}
          </ul>
        )
        return menuLinks
      case 'FIELD_COMMANDER':
        menuLinks = (
          <ul className='sidemenu-ul'>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/'
              activeClassName='activeMI'
              exact
            >
              <li
                className='sidemenu-li'
                id='sidemenu-dashboard'
                onClick={this.psapDashboardClick}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Dashboard
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/IncidentMGT'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li-sub'>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Incident
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/IncidentTeam'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li-sub'>
                <FontAwesomeIcon
                  icon={faUsers}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Team
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/Patients'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li'>
                <FontAwesomeIcon
                  icon={faHospital}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Patients
              </li>
            </NavLink>
            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Analytics"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-analytics" onClick={this.psapAnalyticsClick}>
                <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                Incident Analytics
              </li>
            </NavLink> */}
          </ul>
        )
        return menuLinks
      case 'HOSPITAL_COMMANDER':
        menuLinks = (
          <ul className='sidemenu-ul'>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/'
              activeClassName='activeMI'
              exact
            >
              <li
                className='sidemenu-li'
                id='sidemenu-dashboard'
                onClick={this.psapDashboardClick}
              >
                <FontAwesomeIcon
                  icon={faHome}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Dashboard
              </li>
            </NavLink>
            <NavLink
              className={`sidemenu-nav  ${
                this.props.sideMenuClear.addClearCSS ? 'clearCSS' : ''
              }`}
              to='/Patients'
              activeClassName='activeMI'
              exact
            >
              <li className='sidemenu-li'>
                <FontAwesomeIcon
                  icon={faHospital}
                  size='sm'
                  className='fontawesome-sidemenu'
                />
                Patients
              </li>
            </NavLink>

            {/* <NavLink
              className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
              to="/Analytics"
              activeClassName="activeMI"
              exact
            >
              <li className="sidemenu-li" id="sidemenu-analytics" onClick={this.psapAnalyticsClick}>
                <FontAwesomeIcon icon={faChartBar} size="sm" className="fontawesome-sidemenu" />
                Incident Analytics
              </li>
            </NavLink> */}
          </ul>
        )
        return menuLinks
      default:
        alert('No side menu options')
    }
  }

  // Add conditional jsx for cis sidemenu
  render() {
    return <div id='sidemenu'>{this.menuOptions()}</div>
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  sideMenu: state.sideMenu,
  sideMenuClear: state.sideMenuClear,
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showRegister,
      showMembers,
      showIncidents,
      closeRegisterMember,
      clearCss,
      removeClearCss,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu)
