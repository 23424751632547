import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";

import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

import { InciInstance } from "../../../../../config";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectIncident } from "../../../../../Redux/actions/Incidents/fetchIncidentAction";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";

class IncidentTable extends Component {
  constructor() {
    super();

    this.state = {
      data:
        // Incidents
        // column types must be ordered the same as the fields of the rows objects
        [
          {
            columns: [
              {
                label: "Id",
                field: "id",
                sort: "asc",
                width: 50,
              },
              {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 130,
              },
              {
                label: "Location",
                field: "location",
                sort: "asc",
                width: 150,
              },
              {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 150,
              },
              {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 130,
              },
              {
                label: "CIS",
                field: "cis",
                sort: "asc",
                width: 120,
              },
              {
                label: "Open/Close",
                field: "activity",
                sort: "asc",
                width: 120,
              },
              {
                label: "Edit",
                field: "edit",
                sort: "asc",
                width: 120,
              },
              {
                label: "Delete",
                field: "delete",
                sort: "asc",
                width: 120,
              },
            ],
            rows: [],
          },
        ],
      // Token
      pushedButton: false,
      modal14: false,
      deleteId: null,
      modal15: false,
      statusId: null,
      loading: false,
      done: false,
      show: true,
      error: {},
    };
  }
  toggleModalDelete = (nr, id) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      deleteId: id,
    });
  };
  toggleModalStatus = (nr, id) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
      statusId: id,
    });
  };

  // Close/Open incident button
  statusHandler = async () => {
    try {
      await InciInstance.get(`/incident/${this.state.statusId}/status/`);
      this.setState({
        modal15: !this.state.modal15,
        pushedButton: false,
        deleteId: null,
      });
      this.handleClickIncidents();
    } catch (error) {
      console.log("Change incident status API error");
      this.setState({ error: error, show: true })
    }
  };

  // Delete incident button
  deleteHandler = async () => {
    if (this.state.deleteId !== null) {
      try {
        await InciInstance.delete(`/incident/${this.state.deleteId}/`);
        this.setState({
          modal14: !this.state.modal14,
          pushedButton: false,
          deleteId: null,
        });
      } catch (error) {
        console.log("Delete incident API error");
        this.setState({ error: error, show: true })
      }
    }
  };

  // Show incidents button
  handleClickIncidents = async () => {
    this.setState({ loading: true, pushedButton: true });
    try {
      const res = await InciInstance.get("/incident/");

      this.setState((prevState) => (prevState.data[0].rows = []));

      for (let i = 0; i < res.data.data.length; i++) {
        let status = "Active";
        if (!res.data.data[i].isActive) {
          status = "Inactive";
        }
        let index = res.data.data[i].incidentTimeOfAnnouncement.split("T");
        this.setState((prevState) => ({
          data: [
            {
              ...prevState.data[0],
              rows: [
                ...prevState.data[0].rows,

                {
                  id: res.data.data[i].id,
                  name: res.data.data[i].name,
                  location: res.data.data[i].location,
                  date: index[0],
                  status: status,
                  cis: (
                    <MDBBtn
                      color="primary"
                      onClick={() => this.cisHandler(res.data.data[i].id)}
                      size="sm"
                    >
                      CIS
                    </MDBBtn>
                  ),
                  activity: (
                    <MDBBtn
                      color="primary"
                      onClick={this.toggleModalStatus(15, res.data.data[i].id)}
                      size="sm"
                    >
                      Status
                    </MDBBtn>
                  ),
                  edit: (
                    <MDBBtn
                      color="danger"
                      onClick={() => this.editHandler(res.data.data[i].id)}
                      size="sm"
                    >
                      Edit
                    </MDBBtn>
                  ),
                  delete: (
                    <MDBBtn
                      color="danger"
                      onClick={this.toggleModalDelete(14, res.data.data[i].id)}
                      size="sm"
                    >
                      Delete
                    </MDBBtn>
                  ),
                },
              ],
            },
          ],
        }));
      }
      this.setState({ loading: false, done: true });
    } catch (error) {
      console.log("Get incidents API error");
      this.setState({ loading: false, error: error, show: true })
    }
  };

  // Cis table button
  cisHandler = async (id) => {
    try {
      // Get Incident by id
      const res = await InciInstance.get(`/incident/${id}/`);
      // Redirect and send the payload to the Cis
      this.props.history.push({
        pathname: "/Cis",
        prePopulate: res.data.data,
        populate: true,
      });
      this.props.selectIncident(id);
    } catch (error) {
      console.log("Get incident by id API error");
      this.setState({ error: error, show: true})
    }
  };

  // Edit Incident button
  editHandler = async (id) => {
    try {
      // Get Incident by id
      const res = await InciInstance.get(`/incident/${id}/`);
      // Redirect and send the payload to the form
      this.props.history.push({
        pathname: "/RegisterIncident",
        prePopulate: res.data.data,
        populate: true,
      });
    } catch (error) {
      console.log("Get incident by id API error");
      this.setState({ error: error, show: true})
    }
  };


  handleClose = () => {
    this.setState({show: false});
  };

  render() {
    const IncidentsPostSubmit = (
      <div className="tableNest">
        <MDBDataTable
          maxHeight="200px"
          scrollY
          striped
          bordered
          fixed
          hover
          small
          data={this.state.data[0]}
        />
      </div>
    );

    let ShowIncidents;

    if (this.state.loading === true) {
      ShowIncidents = <Spinner />;
    } else if (this.state.loading === false && this.state.done === true) {
      ShowIncidents = IncidentsPostSubmit;
    }
    return (
      <div>
        <MDBContainer
          style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "0px 0px 0px #888888",
            padding: "2%",
          }}
        >
          <MDBModal
            isOpen={this.state.modal14}
            toggle={this.toggleModalDelete(14)}
            centered
            size="sm"
          >
            <MDBModalHeader toggle={this.toggleModalDelete(14)}>Delete</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.toggleModalDelete(14)} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn color="danger" size="sm" onClick={this.deleteHandler}>
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            isOpen={this.state.modal15}
            toggle={this.toggleModalStatus(15)}
            centered
            size="sm"
          >
            <MDBModalHeader toggle={this.toggleModalStatus(15)}>Change Status</MDBModalHeader>
            <MDBModalBody>Are you sure ?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.toggleModalStatus(15)} size="sm">
                Cancel
              </MDBBtn>
              <MDBBtn color="danger" size="sm" onClick={this.statusHandler}>
                Change
              </MDBBtn>
              <ErrorModal
                onHide={this.handleClose}
                handleClose={this.handleClose}
                show  ={this.state.show}
                error = {this.state.error}
              />
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        {this.state.pushedButton === false ? (
          <div className="button_container text-center">
            <button type="button" className="btn btn-primary" onClick={this.handleClickIncidents}>
              Show Incidents
            </button>
          </div>
        ) : (
          <div />
        )}
        {ShowIncidents}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selectedIncident: state.selectedIncident,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selectIncident,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(IncidentTable);
