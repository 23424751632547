import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PatientTable from "./PatientTable";
import { Button } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import history from "../../../../../history";
import { connect } from "react-redux";
import { InciInstance } from "../../../../../config";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiButton: {
        root: {
          background: "#F1F7FF",
          marginLeft: 25,
          boxShadow: "5px 5px 8px #178BC616",
          border: "2px solid #0B61A4",
          borderRadius: 12,
        },
      },
    },
  });

class PatientMGT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incidentId: "",
      incidentName: "",
    };
  }

  goBack = () => {
    history.push("/Dashboard");
  };

  incidentId = null;

  componentDidMount = async () => {
    await this.getIncidentData();
  };

  getIncidentData = async () => {
    if (this.props.security.user.incidentId && this.props.security.user.incidentId !== undefined) {
      let id = this.props.security.user.incidentId;
      // console.log(this.props.security.user);
      this.setState({ incidentId: id });
      let sitrep = await InciInstance.get(`/incident/${id}/`);
      this.setState({ incidentName: sitrep.data.data.name });
    }
  };

  render() {
    return (
      <MuiThemeProvider theme={getMuiTheme()}>
        <div className="resourceMGTTables">
          <div className="container">
            <div className="row">
              <FontAwesomeIcon icon={faHospital} size="lg" className="fontawesome-dash title-svg" />
              <h1 className="main-heading">
                Incident List
                <span className="text-left table-number ml-3">
                  for incident {this.state.incidentId ? this.state.incidentId : null} - {this.state.incidentName? this.state.incidentName: null}{" "}
                </span>
              </h1>
              <Button className="go-back" onClick={this.goBack}>
                <FontAwesomeIcon icon={faArrowLeft} color="#065097" size="sm" />
                <p style={{ color: "#065097", marginBottom: 0, marginLeft: 5 }}>Back</p>
              </Button>
            </div>
            <div className="row mt-4">
              <div className="table-wrapper">
                <PatientTable />
              </div>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
  userId: state.security.user.user_id,
  userRole: state.security.user.user_roles[0],
  selectedIncident: state.selectIncident,
  dispatchModal: state.toggleDispatchModal.showDispatch,
  incidents: state.incidents,
});

export default connect(mapStateToProps)(PatientMGT);
