import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class TopRightChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated: false
    };
  }

  dataObj = {
    data: [
      {
        labels: ["Manpower", "Vehicles"],
        datasets: [
          {
            label: "Average Force Deployed",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            data: [this.props.data["Man Power"], this.props.data["Vehicles"]]
          }
        ]
      }
    ]
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.dataObj = {
        data: [
          {
            labels: ["Manpower", "Vehicles"],
            datasets: [
              {
                label: " Average Force Deployed",
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "rgba(255,99,132,1)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(255,99,132,1)",
                data: [this.props.data["Man Power"], this.props.data["Vehicles"]]
              }
            ]
          }
        ]
      };
      this.setState({ updated: !this.state.updated });
    }
  }
  render() {
    return (
      <div>
        <Bar
          data={this.dataObj.data[0]}
          width={100}
          height={50}
          options={{
            maintainAspectRatio: true,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ],
              xAxes: [
                {
                  barThickness: 25
                }
              ]
            }
          }}
        />
      </div>
    );
  }
}

export default TopRightChart;
