import React, { Component } from 'react'

class TrainingPlatform extends Component {
  render() {
    return (
      <div style={{ width: '1500px', height: '1200px', overflow: 'hidden' }}>
        <iframe
          src='https://www.resistantproject.eu/training-platform/'
          title='Resistance Training Platform'
          style={{
            position: 'relative',
            left: '-100px',
            width: '1500px',
            height: '1200px',
            top: '-300px',
          }}
          // width='90%'
          // height='700px'
        ></iframe>
      </div>
    )
  }
}

//<div style="width: 500px; height: 500px; overflow: hidden">
//and giving the iframe a relative position:

//<iframe src="..." style="position: relative; left: -100px; top: -100px"></iframe>

export default TrainingPlatform
