import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import SecuredRoute from '../src/securityUtils/SecureRoute'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'

import Logout from './containers/Authentication/Logout'
import DispatchTable from './containers/Screens/Admin/ResourceMGT/Incidents/Dispatch/DispatchTable'
import AnalyticsPage from './containers/Screens/Analytics/AnalyticsPage'

// Common
import Login from './containers/Authentication/Login'
import ProfileSettings from './containers/Screens/Common/ProfileSettings'
import TrainingPlatform from './containers/Screens/Admin/ResourceMGT/Incidents/TrainingPlatform'

// Admin Routes
import AdminDashboard from './containers/Screens/Admin/Dashboard'
import IncidentsMap from './containers/Screens/Admin/ResourceMGT/Incidents/IncidentsMap'
import RegisterUser from './containers/Screens/Admin/ResourceMGT/RegisterUser/User'
import UsersMGT from './containers/Screens/Admin/ResourceMGT/RegisterUser/UsersMGT'
import OrgMGT from './containers/Screens/Admin/ResourceMGT/Organizations/OrgMGT'
import registerOrganization from './containers/Screens/Admin/ResourceMGT/Organizations/RegisterOrganization'
import registerHospital from './containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/Hospital'
import registerEms from './containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/EmergencyMedicalServices'
import registerOther from './containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/Other'
import registerHigherCommand from './containers/Screens/Admin/ResourceMGT/Organizations/AvailableOrganizations/HigherCommand'

// Guest Routes
// eslint-disable-next-line

//PSAP Routes
import EMSIncidentMGT from './containers/Screens/IncidentModule/CisComponents/Incident/IncidentMGT'
import registerIncident from './containers/Screens/Admin/ResourceMGT/Incidents/IncidentForm'
import HospitalCis from './containers/Screens/IncidentModule/CisComponents/HospitalCis'
import ChatApp from './components/Chat/ChatApp'

// Common Incident Space routes
import Cis from './containers/Screens/IncidentModule/Cis'
import SitrepForm from './containers/Screens/IncidentModule/CisComponents/Sitrep'
import IncidentTeam from './containers/Screens/IncidentModule/CisComponents/IncidentTeam'
import FireDSS from './containers/Screens/Analytics/FireDSS'

//New Incident Routes
import IncidentMGT from './containers/Screens/Admin/ResourceMGT/Incidents/IncidentMGT'
import IncidentTable from './containers/Screens/Admin/ResourceMGT/Incidents/IncidentTable'
import PatientMGT from './containers/Screens/Admin/ResourceMGT/Incidents/PatientMGT'

class Routes extends Component {
  render() {
    // Expose the paths that correspond to each user role
    let exposedUrls
    if (this.props.security.validToken) {
      const token = localStorage.getItem('jwtToken')
      const decoded = jwt_decode(token)
      console.log('DECODED', decoded)
      let role = decoded.user_roles[0]

      switch (role) {
        // Admin private routes
        case 'HIGH_COMMANDER':
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path='/' component={AdminDashboard} />
              <SecuredRoute
                exact
                path='/Dashboard'
                component={AdminDashboard}
              />
              <SecuredRoute
                exact
                path='/IncidentsMap'
                component={IncidentsMap}
              />
              <SecuredRoute
                exact
                path='/ProfileSettings'
                component={ProfileSettings}
              />
              <SecuredRoute exact path='/Logout' component={Logout} />
              <SecuredRoute
                exact
                path='/RegisterUser'
                component={RegisterUser}
              />
              <SecuredRoute
                exact
                path='/RegisterOrganization'
                component={registerOrganization}
              />
              <SecuredRoute exact path='/OrgMGT' component={OrgMGT} />
              <SecuredRoute exact path='/UsersMGT' component={UsersMGT} />
              <SecuredRoute
                exact
                path='/RegisterHospital'
                component={registerHospital}
              />
              <SecuredRoute exact path='/RegisterEms' component={registerEms} />
              <SecuredRoute
                exact
                path='/RegisterOther'
                component={registerOther}
              />
              <SecuredRoute
                exact
                path='/RegisterHigherCommand'
                component={registerHigherCommand}
              />
              <SecuredRoute exact path='/Analytics' component={AnalyticsPage} />
              <SecuredRoute
                exact
                path='/RegisterIncident'
                component={registerIncident}
              />
              <SecuredRoute
                exact
                path='/TrainingPlatform'
                component={TrainingPlatform}
              />
              <SecuredRoute
                exact
                path='/IncidentInfo'
                component={IncidentTable}
              />
              <SecuredRoute exact path='/IncidentMGT' component={IncidentMGT} />
              <SecuredRoute
                exact
                path='/DispatchTable'
                component={DispatchTable}
              />
              <SecuredRoute exact path='/Patients' component={PatientMGT} />
            </Switch>
          )
          break
        // Guest Private routes
        case 'EMS':
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path='/' component={Cis} />
              <SecuredRoute exact path='/Dashboard' component={Cis} />
              <SecuredRoute
                exact
                path='/IncidentMGT'
                component={EMSIncidentMGT}
              />
              <SecuredRoute
                exact
                path='/TrainingPlatform'
                component={TrainingPlatform}
              />
              <SecuredRoute
                exact
                path='/DispatchTable'
                component={DispatchTable}
              />
              <SecuredRoute
                exact
                path='/ProfileSettings'
                component={ProfileSettings}
              />
              <SecuredRoute exact path='/Sitrep' component={SitrepForm} />
              <SecuredRoute
                exact
                path='/IncidentTeam'
                component={IncidentTeam}
              />
              <SecuredRoute exact path='/FireDSS' component={FireDSS} />
              <SecuredRoute exact path='/HospitalCis' component={HospitalCis} />
              <SecuredRoute exact path='/Logout' component={Logout} />
              <SecuredRoute exact path='/Patients' component={PatientMGT} />
            </Switch>
          )
          break
        // Psap Private routes
        case 'PSAP':
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path='/Dashboard' component={Cis} />
              <SecuredRoute
                exact
                path='/RegisterIncident'
                component={registerIncident}
              />
              <SecuredRoute
                exact
                path='/TrainingPlatform'
                component={TrainingPlatform}
              />
              <SecuredRoute exact path='/IncidentMGT' component={IncidentMGT} />
              <SecuredRoute
                exact
                path='/DispatchTable'
                component={DispatchTable}
              />
              <SecuredRoute
                exact
                path='/ProfileSettings'
                component={ProfileSettings}
              />
              <SecuredRoute exact path='/Sitrep' component={SitrepForm} />
              <SecuredRoute
                exact
                path='/IncidentTeam'
                component={IncidentTeam}
              />
              <SecuredRoute exact path='/HospitalCis' component={HospitalCis} />
              <SecuredRoute exact path='/FireDSS' component={FireDSS} />
              <SecuredRoute exact path='/Analytics' component={AnalyticsPage} />
              <SecuredRoute exact path='/ChatApp' component={ChatApp} />
              <SecuredRoute exact path='/Logout' component={Logout} />
              <SecuredRoute exact path='/Patients' component={PatientMGT} />
            </Switch>
          )
          break
        case 'HOSPITAL_COMMANDER':
          exposedUrls = (
            <Switch>
              <SecuredRoute exact path='/' component={registerHospital} />
              <SecuredRoute exact path='/Analytics' component={AnalyticsPage} />
              <SecuredRoute exact path='/Patients' component={PatientMGT} />
              <SecuredRoute
                exact
                path='/TrainingPlatform'
                component={TrainingPlatform}
              />
            </Switch>
          )
          break
        default:
          break
      }
    } else {
      exposedUrls = <Route component={Login} />
    }
    return (
      <div>
        <Switch>
          {
            // Public Routes
          }
          {/* <Route exact path="/Login" component={Login} /> */}
          {exposedUrls}
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
})

export default connect(mapStateToProps, null)(Routes)
