import React, { Component } from 'react';
import { OrgInstance, UserInstance } from '../../../../../config';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form } from 'formik';
import { FormRow, FormInput, FormButtons, FormDropdown, FormWrapper } from '../../../../../components/UI/Form/Form'
import * as validation from '../../../../../components/UI/Form/formValidation';

import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      phoneNumber: '',
      email: '',
      position: '',
      role: '',
      organizationType: '',
      organizationId: '',
      specialExpertise: '',
      incident_details: '',
      enabled: true,
      allOrgs: {},
      prePopulate: false,
      loading: false,
      show: false,
      error: {},
    };
  }

  async getAllOrganizations() {
    this.state.allOrgs['HOSPITAL'] = [];
    this.state.allOrgs['EMS'] = [];
    this.state.allOrgs['PSAP_CENTER'] = [];
    this.state.allOrgs['HIGHER_COMMAND'] = [];
    this.state.allOrgs['OTHER'] = [];
    (await OrgInstance.get('all/')).data.data.forEach(org => {
      this.state.allOrgs[org.organizationType].push({ [org.organizationId]: org.name })
    })
  }

  populateFieldsIfEditingUser() {
    if (this.props.location.prePopulate) {
      try {
        this.setState({
          ...this.props.location.prePopulate,
          ...this.props.location.prePopulate.details,
          prePopulate: true
        });
      } catch (error) {
        console.log('Populate User fields error')
        this.setState({ error: error, show: true})
      }
    } 
  }

  // close error modal
  handleClose = () => {
    this.setState({ show: false });
  };

  async componentDidMount() {
    await this.getAllOrganizations();
    this.populateFieldsIfEditingUser();
  }

  onSubmit = async (formValues) => {
    this.state = formValues

    const user = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      username: this.state.username,

      // This avoids changing user password to null if we're editing - not creating - a user.
      ...(this.state.prePopulate && !this.state.password ? null : { password: this.state.password }),

      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      details: {
        role: this.state.role,
        organizationType: this.state.organizationType,
        organizationId: this.state.organizationId,
        specialExpertise: this.state.specialExpertise,
        position: this.state.position,
        incident_details: "",
        enabled: true
      }
    }
    try {
      // this.setState({ loading: true });
      if (this.state.prePopulate) {
        await UserInstance.patch(`user/${this.props.location.prePopulate.id}/`, user);
      } else {
        await UserInstance.post('user/', user);
        console.log('here', this.state, this.props)
        this.setState({ show: true})
      }
      this.setState({ loading: false });
      await this.props.history.push('/Dashboard');
    }
    catch (error) {
      console.log('Register User API error');
      this.setState({ loading: false, error: error, show: true });
      console.log('user register', this.state)
    }
  }

  render() {
    let form = (
      <Formik
        enableReinitialize={true}  // This prop is needed for populateFieldsIfEditingUser method.
        initialValues={{...this.state}}
        onSubmit={(formValues) => this.onSubmit(formValues)}>
        {({ values }) => (
          <Form className='custom-form'>
            <FormRow>
              <FormInput name='firstName' validate={(value) => validation.inputIsRequired('Name', value)} />
              <FormInput name='lastName'  validate={(value) => validation.inputIsRequired('Last name', value)} />
              <FormInput name='email' 
                validate={(value) => validation.email(value, 
                    this.state.prePopulate ? this.props.location.prePopulate.email : null)
                } 
              />
              <FormInput name='phoneNumber'validate={(value) => validation.phoneNumber(value)} />
            </FormRow>
            <FormRow>
              <FormDropdown name='specialExpertise' size='col-md-6'
                validate={value => validation.selectionIsRequired('Special Expertise', value)}
                options={[
                  { 'NO_MEDICAL_SKILLS': 'No Medical Skills' },
                  { 'BASIC_LIFE_SUPPORT': 'Basic Life Support' },
                  { 'ADVANCED_LIFE_SUPPORT': 'Advance Life Support' },
                  { 'SPECIALIST_MEDICAL': 'Medical Specialist' }
                ]} 
              />
            </FormRow>
            <FormRow>
              <FormDropdown name='organizationType'
                validate={value => validation.selectionIsRequired('Organization Type', value)}
                options={[ 
                  { 'HOSPITAL': 'Hospital' },
                  { 'EMS': 'EMS' }
                ]} 
              />
              <FormDropdown name='organizationId' label='Organization' 
                disable={!values.organizationType}
                validate={value => validation.organizationIdField(value, this.state.allOrgs[values.organizationType])}
                options={this.state.allOrgs[values.organizationType]} 
              />
              <FormDropdown name='role' label='User Role'
                validate={value => validation.selectionIsRequired('User Role', value)}
                options={[
                  { 'EMS': 'EMS' },
                  { 'HIGH_COMMANDER': 'High Commander' },
                  { 'HOSPITAL_COMMANDER': 'Hospital Commander' }]} 
              />
              <FormDropdown name='position'
                validate={value => validation.selectionIsRequired('Position', value)}
                options={[
                  { 'LOW': 'Low Ranking Officer' },
                  { 'MEDIUM': 'Medium Ranking Officer' },
                  { 'HIGH': 'High Ranking Officer' } ]} 
              />
            </FormRow>
            <FormRow>
              <FormInput name='username'
                validate={value => validation.username(value, 6, 100, 
                  this.state.prePopulate ? this.props.location.prePopulate.username : null)
                } 
              />
              <FormInput name='password' 
                validate={value => this.state.prePopulate ? null : validation.minMax('Password', value, 6, 100)} 
              />
            </FormRow>
            <FormRow>
            <FormButtons
              text={this.state.prePopulate ? '* All fields except password are required.':'* All fields are required.'}
            />
            </FormRow>
            <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show={this.state.show}
            error={this.state.error}
          />
          </Form>
        )}
      </Formik>
    );

    if (this.state.loading === true) {
      form = <Spinner />;
    }

    return (
      <FormWrapper
        parentClass='registerUser'
        icon={faPencilAlt}
        mainHeading={this.state.prePopulate ? 'Modify User' : 'Register New User'}
        componentHeading={this.state.prePopulate ? 'Modify User Form' : 'New User Form'}>
        {form}
      </FormWrapper>
    );
  }
}

export default User;
