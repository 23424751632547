import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { TriageInstance } from "../../../../../config";
import { useSelector } from "react-redux";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        fontFamily: "Poppins",
        paper: {
          boxShadow: "0px 3px 10px #00000017",
          borderRadius: "20px",
          marginTop: 10,
          paddingLeft: 20,
          paddingRight: 10,
          fontFamily: "Poppins",
        },
      },
      MuiTableCell: {
        root: {
          marginTop: 10,
          border: "none!important",
          padding: 14,
        },
        body: {
          whiteSpace: "nowrap",
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          display: "none",
        },
        fixedLeft: {
          display: "none",
        },
      },
      MUIDataTableHeadCell: {
        data: {
          color: "#343434",
          fontSize: 17,
          fontWeight: 600,
          fontFamily: "Montserrat",
        },
        fixedHeader: {
          border: "none",
        },
      },
    },
  });

export default function PatientTable(props) {
  const [patients, setPatients] = useState([]);
  const [assignedPatients, setAssignedPatients] = useState([]);
  const incidents = useSelector((state) => state.incidents.incidents.incidents);
  const userRoles = useSelector((state) => state.security.user.user_roles);
  console.log(userRoles);

  const getAllPatients = async () => {
    await TriageInstance.get("/patient")
      .then((response) => {
        const patients = response.data.data;
        setPatients(patients);
        console.log(patients, "patients");

        userRoles.map((role) => {
          if (role === "PSAP" || role === "HOSPITAL_COMMANDER" || role === "EMS") {
            patients.map((patient) => {
              incidents.map((inc) => {
                if (patient.incident === inc.id) {
                  setAssignedPatients([patient]);
                } else {
                  console.log("NO PATIENTS");
                }
              });
            });
          } else {
            setAssignedPatients(patients);
          }
        });
      })

      .catch((error) => console.error(`Error : ${error}`));
  };

  useEffect(() => {
    getAllPatients();
    console.log(assignedPatients, "ass");
  }, []);

  const columns = [
    {
      label: "TAG",
      name: "TAG",
      options: {
        filter: true,
        setCellProps: (value) => ({ style: { fontWeight: "bold", color: "#188EC9" } }),
      },
    },
    "Location",
    "Hr",
    "O2",
    {
      label: "Triage 1 ",
      name: "Triage1",
      options: {
        filter: true,
        setCellProps: (value) => {
          return {
            style: {
              backgroundColor:
                value > 2 && value < 9
                  ? "#D52236"
                  : value > 8 && value < 13
                  ? "#FFDA1E"
                  : value > 13 && value < 16
                  ? "#40EE45"
                  : null,
            },
          };
        },
      },
    },
    {
      label: "Triage 2",
      name: "Triage2",
      options: {
        filter: true,
        setCellProps: (value) => {
          return {
            style: {
              backgroundColor:
                value > 2 && value < 9
                  ? "#D52236"
                  : value > 8 && value < 13
                  ? "#FFDA1E"
                  : value > 13 && value < 16
                  ? "#40EE45"
                  : null,
            },
          };
        },
      },
    },
    "Ref.DG",
    "FR",
    "Time to Hospital",
    "Spec",
    "Referred",
    "Accepted",
    "Vehicle",
    "Transport Depart",
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    fixedHeader: false,
    // fixedSelectColumn: false,
    rowHover: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: rowIndex % 2 === 0 ? { background: "#fbfff0" } : { background: "#EFF6FF" },
      };
    },
  };

  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={assignedPatients.map((patient) => {
            const {
              accepted,
              id,
              refDG,
              referred,
              triage1,
              triage2,
              location,
              fr,
              time,
              spec,
              transportDepart,
              vehicle,
            } = patient;

            return [
              id,
              location,
              triage2.hr,
              triage1.o2,
              triage1.triage_score,
              triage2.triage_score,
              refDG,
              fr,
              time.split("T")[1].split("Z"),
              spec,
              referred.split("T")[1].split("Z"),
              accepted.split("T")[0],
              vehicle,
              transportDepart.split("T")[1].split("Z"),
            ];
            // }
          })}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}

// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import {
//   fetchIncidents,
//   editIncident,
//   selectIncident,
//   deleteIncident,
// } from "../../../../../Redux/actions/Incidents/fetchIncidentAction";
// import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
// import {
//   showDispatchModal,
//   hideDispatchModal,
// } from "../../../../../Redux/actions/UI/toggleDispatchModalAction";
// import DataTable from "react-data-table-component";

// import {
//   MDBContainer,
//   MDBBtn,
//   MDBModal,
//   MDBModalBody,
//   MDBModalHeader,
//   MDBModalFooter,
// } from "mdbreact";
// import DispatchModal from "./Dispatch/DispatchModal";

// class PatientTable extends Component {
//   constructor() {
//     super();
//     this.state = {
//       incidentId: "",
//       patients: []
//     };
//   }

//   componentDidMount = async () => {
//     await TriageInstance.get("/patient")
//       .then((response) => {
//         const patients = response.data.data;
//         this.setState({patients:patients})
//         console.log(patients, "patients");
//       })
//       .catch((error) => console.error(`Error : ${error}`));
//   };

//     columns = [
//     {
//       label: "TAG",
//       name: "TAG",
//       options: {
//         filter: true,
//         // setCellHeaderProps: value => ({ style: { textDecoration: 'underline', color: '#188EC9' } }),
//         setCellProps: (value) => ({ style: { fontWeight: "bold", color: "#188EC9" } }),

//         // setRowProps: (row, dataIndex, rowIndex) => {
//         //   return {

//         //     style: { border: '4px solid blue' },
//         //   };
//         // },
//       },
//     },
//     "Location",
//     "Hr",
//     "O2",
//     {
//       label: "Triage1",
//       name: "Triage1",
//       options: {
//         filter: true,
//         setCellProps: (value) => {
//           return {
//             style: { backgroundColor: value > 2 && value < 9 ? "#D52236" :   null,backgroundColor:  value > 8 && value < 13 ? "#FFDA1E":  null,background:   value > 13 && value < 16 ? "#40EE45" : null}
//             // backgroundColor: value > 2 && value < 9 ? "#D52236": 'null',
//           };
//         },
//       },
//     },
//     {
//       label: "Triage2",
//       name: "Triage2",
//       options: {
//         filter: true,
//         setCellProps: (value) => {
//           return {
//             // style: { backgroundColor: value > 2 && value < 9 ? "#D52236" :   'red',backgroundColor:  value > 8 && value < 13 ? "#FFDA1E":  'red',}
//             background: value > 2 && value < 9 ? "#D52236": "#D52236",
//           };
//         },
//       },
//     },
//     "Ref.DG",
//     "FR",
//     "Time to Hospital",
//     "Spec",
//     "Referred",
//     "Accepted",
//     "Vehicle",
//     "Transport Depart",
//   ];

//    options = {
//     filter: true,
//     filterType: "dropdown",
//     fixedHeader: false,
//     // fixedSelectColumn: false,
//     rowHover: false,
//     setRowProps: (row, dataIndex, rowIndex) => {
//       return {
//         style: rowIndex % 2 === 0 ? { background: "#fbfff0" } : { background: "#EFF6FF" },
//         // style: {background: '#EFF6FF'}
//       };
//     },
//   };

//   render() {
//   return (
//     <React.Fragment>
//       <MuiThemeProvider theme={this.getMuiTheme}>
//         <MUIDataTable
//           creators={this.state.patients}
//           data={this.state.patients.map((patient) => {
//             const {
//               accepted,
//               id,
//               refDG,
//               referred,
//               triage1,
//               triage2,
//               location,
//               fr,
//               transportDepart,
//               vehicle,
//             } = patient;
//             if ( patient.incident === this.state.incidentId ){
//             return [
//               id,
//               location,
//               triage2.hr,
//               triage1.o2,
//               triage1.triage_score,
//               triage2.triage_score,
//               refDG,
//               fr,
//               referred.split("T")[1],
//               accepted.split("T")[0],
//               vehicle,
//               transportDepart.split("T")[0],
//             ]; }
//           })}
//           columns={this.columns}
//           options={this.options}
//         />
//       </MuiThemeProvider>
//     </React.Fragment>
//   );
// }
// }

// const mapStateToProps = (state) => {
//   return {
//     incidents: state.incidents,
//     modal: state.toggleModal.show,
//     dispatchModal: state.toggleDispatchModal.showDispatch,
//     selectedIncident: state.selectIncident,
//     deletedIncident: state.deleteIncident,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       fetchIncidents,
//       editIncident,
//       selectIncident,
//       deleteIncident,
//       showModal,
//       hideModal,
//       showDispatchModal,
//       hideDispatchModal,
//     },
//     dispatch
//   );
// };

// export default connect(mapStateToProps, mapDispatchToProps)(PatientTable);
