import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { login } from '../../Redux/actions/Users/securityActions'
import classnames from 'classnames'
import Spinner from '../../components/UI/Spinner/Spinner'
import LoginLogo from '../../assets/images/loginlogo.png'
import axios from 'axios'
import Checkbox from '../../components/Checkbox'
import environment from '../../environment'

import ErrorModal from '../../components/UI/Modal/ErrorModal'

class Login extends Component {
  constructor() {
    super()
    this.state = {
      username: '',
      password: '',
      rememberMe: false,
      valUsername: '',
      valPassword: '',
      show: false,
      loading: false,
      error: {},
    }
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    const rememberMe = localStorage.getItem('rememberMe') === 'true'
    const username = rememberMe ? localStorage.getItem('username') : ''
    this.setState({ username, rememberMe })
    let url = window.location.href
    this.acceptDenyFunction(url)
    console.log(this.props, 'tr')
  }

  componentDidUpdate = async (prevProps) => {
    // Typical usage (don't forget to compare props):
    if (this.props !== prevProps) {
      if (this.props.errors.response) {
        if (this.props.errors.response.status === 401) {
          await this.setState({ show: true })
        }
      }
      this.setState({ loading: false })
    }
  }

  acceptDenyFunction = (url) => {
    if (url.includes('deny')) {
      let exampleUrl = new URL(url)
      let searchParams = new URLSearchParams(exampleUrl.search)
      let userId = searchParams.get('uid')
      let incidentId = searchParams.get('iid')
      if (window.confirm('Click ok to deny incident participation')) {
        try {
          axios.patch(
            `${environment.mainUrl}` +
              `incidents/incident/${incidentId}/decide-assignment/`,
            {
              userId: userId,
              action: 'REJECTED',
            }
          )
          console.log('it happened probably')
        } catch (error) {
          console.log('did not happen', error)
        }
      } else {
        axios.patch(
          `${environment.mainUrl}` +
            `incidents/incident/${incidentId}/decide-assignment/`,
          {
            userId: userId,
            action: 'ACCEPTED',
          }
        )
      }
    } else if (url.includes('accept')) {
      let exampleUrl = new URL(url)
      let searchParams = new URLSearchParams(exampleUrl.search)
      let userId = searchParams.get('uid')
      let incidentId = searchParams.get('iid')
      if (window.confirm('Click ok to accept incident participation')) {
        axios.patch(
          `${environment.mainUrl}` +
            `incidents/incident/${incidentId}/decide-assignment/`,
          {
            userId: userId,
            action: 'ACCEPTED',
          }
        )
      } else {
        axios.patch(
          `${environment.mainUrl}` +
            `incidents/incident/${incidentId}/decide-assignment/`,
          {
            userId: userId,
            action: 'REJECTED',
          }
        )
      }
    }
  }
  // Check input fields for errors
  validate = () => {
    let isError = false

    if (this.state.username.length < 3 || this.state.username.length > 100) {
      isError = true
      this.setState({ loading: false })
      this.setState({
        valUsername: 'Username must be between 3 and 100 characters',
      })
    }

    if (this.state.password.length < 3 || this.state.password.length > 100) {
      isError = true
      this.setState({ loading: false })
      this.setState({
        valPassword: 'Password  must be between 3 and 100 characters',
      })
    }

    return isError
  }

  onSubmit(e) {
    e.preventDefault()

    const { username, rememberMe } = this.state
    localStorage.setItem('rememberMe', rememberMe)
    localStorage.setItem('username', rememberMe ? username : '')

    this.setState({
      loading: true,
      valUsername: '',
      valPassword: '',
    })

    const err = this.validate()

    if (!err) {
      const LoginRequest = {
        username: this.state.username,
        password: this.state.password,
      }
      this.props.login(LoginRequest)
      this.props.history.push({ pathname: '/Dashboard' })
    }
  }

  // componentDidMount() {
  //   if (localStorage.getItem("jwtToken")) {
  //     let myDecipher = this.decipher("randomDecoder");

  //     this.setState({
  //       username: localStorage.getItem("username"),
  //       password: myDecipher(localStorage.getItem("password"))
  //     });
  //   } else {
  //     localStorage.clear();
  //   }
  // }

  handleChange = (event) => {
    const input = event.target
    const value = input.type === 'checkbox' ? input.checked : input.value

    this.setState({ [input.name]: value })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  // close error modal
  handleClose = () => {
    this.setState({ show: false })
  }

  componentWillUnmount() {
    this.setState({ show: false })
  }

  render() {
    const st = this.state
    let form = (
      <form onSubmit={this.onSubmit}>
        <div className='form-group'>
          <label htmlFor='username' className='form-field-label'>
            Username
          </label>
          <input
            type='text'
            className={classnames(
              'form-control form-control-lg form-input-field',
              {
                'is-invalid': st.valUsername,
              }
            )}
            placeholder='Username'
            name='username'
            value={this.state.username}
            onChange={this.onChange}
          />
          {st.valUsername && (
            <div className='invalid-feedback'>{st.valUsername}</div>
          )}
        </div>
        <div className='form-group'>
          <label htmlFor='password' className='form-field-label'>
            Password
          </label>
          <input
            type='password'
            className={classnames(
              'form-control form-control-lg form-input-field',
              {
                'is-invalid': st.valPassword,
              }
            )}
            placeholder='Password'
            name='password'
            value={this.state.password}
            onChange={this.onChange}
          />
          {st.valPassword && (
            <div className='invalid-feedback'>{st.valPassword}</div>
          )}
        </div>
        <div>
          <Checkbox
            type='checkbox'
            wrapper='remember-wrapper'
            name='rememberMe'
            label='Remember Me'
            checked={st.rememberMe}
            onChange={this.handleChange}
          />
          <Link className='fp-link' to='/ForgotPassword'>
            Forgot Password?
          </Link>
        </div>
        <input
          type='submit'
          className='btn btn-info btn-block mt-2 login-button'
          value='Login'
        />
        <div>
          <ErrorModal
            onHide={this.handleClose}
            handleClose={this.handleClose}
            show={this.state.show}
            status={this.props.errors.status}
            message={this.props.errors.message}
          />
        </div>
      </form>
    )
    if (this.state.loading === true) {
      form = <Spinner />
    }

    return (
      <>
        <div className='login-logo-container'>
          <img className='login-logo' src={LoginLogo} alt='' />
        </div>
        <div className='container' id='login-container'>
          <div className='row'>
            <div className='col-6' id='login'>
              <div className='login-title-wrapper'>
                <h1 className='display-4 login-title text-center'>Log in</h1>
              </div>
              {form}
            </div>
          </div>
        </div>
        <div className='copyright-wrapper'>
          Copyright © 2020 Konnektable Technologies ltd
        </div>
      </>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.errors,
})

export default connect(mapStateToProps, { login })(Login)
