import React, { Component } from "react";
import { InciInstance } from "../../../../../config";
import moment from "moment";
import { connect } from "react-redux";
import DispatchModal from "../Incidents/Dispatch/DispatchModal";
import { bindActionCreators } from "redux";
import { showModal, hideModal } from "../../../../../Redux/actions/UI/toggleModalAction";
import {
  showDispatchModal,
  hideDispatchModal,
} from "../../../../../Redux/actions/UI/toggleDispatchModalAction";
import { selectIncident } from "../../../../../Redux/actions/Incidents/fetchIncidentAction";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      notifications: [],
      id: [],
      loading: false,
      show: false,
      error: {}
    };
  }

  getNotificationData = async () => {
    this.setState({ loading: true });
    try {
      let notifications = await InciInstance.get(`/notification/`);
      this.setState({ notifications: notifications.data.data, loading: false });
    } catch (error) {
      console.log("Error during consuming employees api");
      this.setState({ error: error, show: true})
    }
  };

  componentDidMount() {
    this.getNotificationData();
  }

  refreshPage = async () => {
    await this.getNotificationData();
  };

  handleClose = () => {
    this.setState({show: false});
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    } else {
      return (
        <React.Fragment>
          <div className="dash-secondary-heading">NOTIFICATIONS</div>
          <ul className="notifications-list">
            {this.state.notifications.map((notification) =>
            (!notification.text) ? (
              !notification.isHandled ? (
                <li
                  key={notification.id}
                  onClick={() => {
                    this.props.selectIncident(notification.incident);
                    this.props.showDispatchModal();
                  }}
                >
                  <span className="incident-number-notification">
                    ID: {"  "}
                    {notification.incident}
                  </span>
                  <span className="requestedEms-number-notification-false">
                    Reinforcements Needed: {notification.requestedEMS}
                  </span>
                  <span className="notification-time">
                    {moment(notification.createdDate).format("h:mmA")}
                  </span>
                </li>
              ) : (
                <li
                  key={notification.id}
                  onClick={() => {
                    this.props.selectIncident(notification.incident);
                    this.props.showDispatchModal();
                  }}
                >
                  <span className="incident-number-notification">
                    {" "}
                    ID:{"  "}
                    {notification.incident}
                  </span>
                  <span className="requestedEms-number-notification-true">
                    Reinforcements Needed: {notification.requestedEMS}
                  </span>
                  <span className="notification-time">
                    {moment(notification.createdDate).format("h:mmA")}
                  </span>
                </li>
              ) ) : (<li className="incident-number-notification">ID:{"  "}{notification.incident}<span id="notification-deny-text">{notification.text}</span><span className="notification-time">
              {moment(notification.createdDate).format("h:mmA")}
            </span></li>)  
            )} 
          </ul>
          <DispatchModal
            isOpen={this.props.dispatchModal}
            toggle={this.props.dispatchModal}
            isClosed={() => this.props.hideDispatchModal()}
            incident={this.props.selectedIncident}
          />
            <ErrorModal
          onHide={this.handleClose}
          handleClose={this.handleClose}
          show  ={this.state.show}
          error = {this.state.error}
      />
        
          <div className="more-updates" onClick={this.refreshPage}>
            More Updates
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    incidents: state.incidents,
    modal: state.toggleModal.show,
    dispatchModal: state.toggleDispatchModal.showDispatch,
    selectedIncident: state.selectIncident,
    users: state.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showDispatchModal,
      hideDispatchModal,
      selectIncident,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
