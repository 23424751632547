import React, { Component } from 'react'
import { fetchOrganizations } from '../../../Redux/actions/Organizations/OrganizationActions'
// import TopLeftChart from "../Analytics/TopLeftChart";
import IncidentsMap from '../Admin/ResourceMGT/Incidents/IncidentsMap'
import Notifications from '../../Screens/Admin/ResourceMGT/Notifications/Notifications'
import {
  fetchAnalytics,
  fetchYears,
  fetchRegions,
  selectYear,
  selectRegion,
} from '../../../Redux/actions/Analytics/analyticsAction'
import { fetchIncidents } from '../../../Redux/actions/Incidents/fetchIncidentAction'
import {
  showMembers,
  showRegister,
  closeRegisterMember,
  showIncidents,
} from '../../../Redux/actions/UI/sideMenuActions'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import Dropdown from 'react-bootstrap/Dropdown'
import ChatApp from '../../../components/Chat/ChatApp'
import { InciInstance } from '../../../config'
import TweetsFeed from '../../../components/Tweets/TweetsFeed'

class Dashboard extends Component {
  constructor() {
    super()
    this.state = {
      showChatModal: false,
    }
  }
  componentDidMount = async () => {
    this.props.fetchOrganizations()
    this.props.fetchIncidents()
    this.props.fetchAnalytics()
    this.props.fetchYears()
    this.props.fetchRegions(this.props.year)
    console.log(this.props.logi)
  }

  componentDidUpdate(prevProps) {
    if (this.props.year !== prevProps.year) {
      this.props.fetchRegions(this.props.year)
    }
  }

  countOrg(org) {
    if (this.props.organizations === undefined) {
      return null
    } else if (this.props.organizations) {
      for (let i = 0; i < this.props.organizations.length; i++) {
        var orgCount = this.props.organizations.reduce(function (n, count) {
          return n + (count.organizationType === org)
        }, 0)
      }
      return orgCount
    }
  }

  yearDropdownOptions = () => {
    if (this.props.years === undefined) {
      return null
    } else if (this.props.years) {
      return this.props.years.map((year) => {
        return (
          <Dropdown.Item
            key={year}
            id={year}
            onClick={() => this.props.selectYear(year)}
          >
            {year}
          </Dropdown.Item>
        )
      })
    }
  }

  regionDropdownOptions = () => {
    if (this.props.regions === undefined) {
      return null
    } else if (this.props.regions) {
      return this.props.regions.map((region) => {
        return (
          <Dropdown.Item
            key={Math.random()}
            id={region}
            onClick={() => this.props.selectRegion(region)}
          >
            {region}
          </Dropdown.Item>
        )
      })
    }
  }
  handleChatModal = (e) => {
    this.setState({ showChatModal: true })
  }
  render() {
    return (
      <div className='grid-container'>
        <div className='Header'>
          <FontAwesomeIcon
            icon={faHome}
            size='lg'
            className='fontawesome-dash'
            style={{ float: 'left' }}
          />
          <h1 className='dash-heading' style={{ float: 'left' }}>
            Dashboard
          </h1>
          <button
            className='btn  btn-sm'
            id='request-troops-button'
            onClick={() => {
              this.handleChatModal()
            }}
          >
            Chat
          </button>
          <ChatApp
            show={this.state.showChatModal}
            isClosed={() => this.setState({ showChatModal: false })}
          />
        </div>
        <div className='Orgs'>
          <h2 className='dash-secondary-heading'>ORGANISATIONS</h2>
          <div className='text-dashboard'>
            Psap Centers, Hospitals, EMS, Higher Command or By-standers.
          </div>
          <div className='btn-group dropdown-padding'>
            <Link
              to='/RegisterOrganization'
              onClick={() => {
                this.props.showRegister()
              }}
            >
              <button
                type='button'
                data-testid='org-button'
                className=' dashboard-register-button float-left'
              >
                New Organisation
              </button>
            </Link>
            <Link
              to='/OrgMGT'
              onClick={() => {
                this.props.showMembers()
              }}
            >
              <button
                type='button'
                data-testid='org-button'
                className=' dash-view-button float-right'
              >
                View List
              </button>
            </Link>
          </div>
        </div>

        <div className='Members'>
          <h2 className='dash-secondary-heading'>USERS</h2>
          <div className='text-dashboard'>
            First responders, doctors, specialists and other individual members
            of Organisations.
          </div>
          <div className='btn-group dropdown-padding'>
            <Link
              to='/RegisterUser'
              onClick={() => {
                this.props.showRegister()
              }}
            >
              <button
                type='button'
                data-testid='user-button'
                className=' dashboard-register-button float-left'
              >
                New User
              </button>
            </Link>
            <Link
              to={{ pathname: '/UsersMGT', populate: false }}
              onClick={() => {
                this.props.showMembers()
              }}
            >
              <button
                type='button'
                data-testid='user-button'
                className='dash-view-button float-right'
              >
                View List
              </button>
            </Link>
          </div>
        </div>
        <div className='Tweets'>
          <h2 className='dash-secondary-heading'>TWEETS FEED</h2>
          <TweetsFeed />
        </div>
        <div className='Incidents'>
          <h2 className='dash-secondary-heading'>INCIDENT MANAGEMENT</h2>
          <div className='text-dashboard'>
            Register a new incident or view the list of the current running
            incidents around the country.
          </div>
          <div className='btn-group dropdown-padding'>
            <Link
              to='/RegisterIncident'
              onClick={() => {
                this.props.showRegister()
              }}
            >
              <button
                type='button'
                data-testid='org-button'
                className='dashboard-register-button'
              >
                New Incident
              </button>
            </Link>
            <Link
              to='/IncidentMGT'
              onClick={() => {
                this.props.showMembers()
              }}
            >
              <button
                type='button'
                data-testid='org-button'
                className=' dash-view-button'
              >
                View List
              </button>
            </Link>
          </div>
        </div>
        <div className='Map'>
          <IncidentsMap />
        </div>
        <div className='Notifications'>
          <Notifications />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    organizations: state.organization.org,
    anal: state.analytics,
    incidents: state.incidents,
    year: state.dash.year,
    region: state.dash.region,
    years: state.years.availableYears,
    regions: state.regions.availableRegions,
  }
}

export default connect(mapStateToProps, {
  fetchOrganizations,
  fetchAnalytics,
  fetchIncidents,
  selectRegion,
  selectYear,
  fetchYears,
  fetchRegions,
  showMembers,
  showRegister,
  showIncidents,
  closeRegisterMember,
})(Dashboard)
