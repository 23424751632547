import React, { Component } from "react";
import UsersTable from "./UsersTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";

class UsersMGT extends Component {
    render() {
        return (
            <div className="resourceMGTTables">
                <div className="container">
                    <div className="row ">
                        <FontAwesomeIcon icon={faUserFriends} size="lg" className="fontawesome-dash title-svg" /><h1 className="main-heading">Show User List</h1>
                    </div>
                    <div className="row mt-4">
                        <div className="table-wrapper">
                            <h5 className="text-left table-number mb-3" >Users</h5>
                            <UsersTable />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UsersMGT;
