import React, { Component } from "react";
import { InciInstance, GoogleAPIKey } from "../../../../../config";
import GoogleMapReact from "google-map-react";
import ErrorModal from "../../../../../components/UI/Modal/ErrorModal";

class IncidentsMap extends Component {
  static defaultProps = {
    center: { lat: 53.36, lng: -6.26 },
    zoom: 2,
  };

  setInfoWindow = (incident) => `
    <div style="margin: 10px;">
      <div style="font-size: 18px; font-weight: bold;">
        ${incident.name}
      </div>
      <div style="font-size: 14px; padding-top: 5px;">
        <b>Description:</b> ${incident.description}
      </div>
      <div style="font-size: 14px; padding-top: 5px;">
        <b>Location:</b> ${incident.location}
      </div>
      <div style="font-size: 14px; padding-top: 5px;">
        <b>Is Active?</b> ${incident.isActive}
      </div>
    </div>`;

  async getIncidentList() {
    try {
      return await (await InciInstance.get(`/incident/`)).data;
    } catch (err) {
      throw Error("Get Incidents List API error");
    }
  }

  renderMarkers = async (map, maps) => {
    const incident_list = await this.getIncidentList();
    let infowindows = [];
    let markers = [];

    incident_list.data.forEach((incident) => {
      markers.push(
        new maps.Marker({
          position: {
            lat: Number(incident.lat),
            lng: Number(incident.lng),
          },
          map,
        })
      );

      infowindows.push(
        new maps.InfoWindow({
          content: this.setInfoWindow(incident),
        })
      );
    });

    markers.forEach((marker, i) => {
      marker.addListener("mouseover", () => {
        infowindows[i].open(map, marker);
      });
      marker.addListener("mouseout", () => {
        infowindows[i].close(map, marker);
      });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ height: "60vh", width: "100%", padding: "0", margin: "0" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GoogleAPIKey }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default IncidentsMap;
