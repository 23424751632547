import React, { Component } from "react";
import { UserInstance } from "../../config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../Redux/actions/Users/securityActions";
import concordeImg from "../../assets/images/concorder.png";
import UserSettings from "../../assets/user-settings.svg"
import Logout from "../../assets/logout.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBell } from "@fortawesome/free-solid-svg-icons";
import DrawerToggleButton from "../UI/Navigation/SideDrawer/DrawerToggleButton";
import { ButtonToolbar, Dropdown } from "react-bootstrap";

class HeaderTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getUser = async () => {
    try {
      this.setState({ ...await (await UserInstance.get(`user/${this.props.security.user.user_id}`)).data.data })
    }
    catch (error) {
      console.log("Get User API error",error);
    }
  }

  componentDidMount = async () => {
    this.getUser();
  }

  componentDidUpdate = async () => {
    this.getUser();
  }

  logout = () => {
    this.props.logout();
    window.location.href = "/";
  };

  profileSettings = () => {
    window.location.href = "/ProfileSettings";
  };

  renderHeaderLogo() {
    return (
      <React.Fragment>
        <img id="header-logo" alt="concorde-logo" src={concordeImg} />
        <div className="toolbar__toggle-button">
          <DrawerToggleButton click={this.props.drawerClickHandler} />
        </div>
      </React.Fragment>
    )
  };

  renderBellIcon() {
    return (
    <Dropdown className="">
      <Dropdown.Toggle id="dropdown-action-user-org">
        <FontAwesomeIcon icon={faBell} size="lg" className="fontawesome-header" />
      </Dropdown.Toggle>
    </Dropdown>
    )
  };

  renderUserDropdownPhoto() {
    if (this.state.avatar) {
      return <img alt="avatar" src={this.state.avatar} id="user-dropdown-photo" width="50" height="30" />
    } else {
      return <FontAwesomeIcon icon={faUser} size="lg" className="fontawesome-header" />
    }
  }

  renderUserDropdown() {
    return (
      <ButtonToolbar>
        <Dropdown>
          <Dropdown.Toggle id="user-dropdown-toggle">
            {this.renderUserDropdownPhoto()}
            <p id="user-dropdown-username">{this.state.username}</p>
          </Dropdown.Toggle>
          <Dropdown.Menu>
          <Dropdown.Item className="user-dropdown-item" eventKey="2">
            <div class="user-dropdown-li-img">
              <img alt="user-settings" src={UserSettings} />
            </div>
            <Link to="/ProfileSettings" onClick={this.profileSettings}>
              Profile Settings
            </Link>
            </Dropdown.Item>
            <Dropdown.Item className="user-dropdown-item" eventKey="1">
            <div class="user-dropdown-li-img">
              <img alt="logout-icon" src={Logout} />
            </div>
              <Link to="/logout" onClick={this.logout}>
                Log Out
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ButtonToolbar>
    )
  };

  render() {
    const { validToken, user } = this.props.security;
    return (
      <div id="header-container">
        {validToken && user ? (
          <div>
            <div className="row" id="header-row">
              <div className="col-9">
                {this.renderHeaderLogo()}
              </div>
              <div className="header-options">
                {this.renderBellIcon()}
              </div>
              <div className="col-2 header-options">
                {this.renderUserDropdown()}
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, { logout })(HeaderTest);
