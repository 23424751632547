import React, { Component } from "react";
import { InciInstance, GoogleAPIKey } from "../../../../../config";
import Spinner from "../../../../../components/UI/Spinner/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import _ from "underscore";
import { connect } from "react-redux";

class CisMapOfficial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: true,
      error: {},
    };
  }

  polygonTimer = null;
  markerTimer = null;
  intervalTimer = null;

  map = null;
  drawingManager = null;
  service = null;
  mapCenter = null;

  markerApiResponse = null;
  markers = {};
  markersString = {};
  markerArray = [];

  polygonApiResponse = null;
  polygons = {};
  polygonsString = {};
  polygonArray = [];

  polygonColorOptions = ["safe", "caution", "danger"];
  markerOptions = ["Helicopter", "Command", "Access", "Medical", "Patient", "Danger"];

  getAuthHeader() {
    const authorizationHeader = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };
    return authorizationHeader;
  }

  async toggleLoading(promise) {
    this.setState({ loading: true });
    try {
      const result = await promise;
      this.setState({ loading: false });
      return result;
    } catch (error) {
      // this.setState({ loading: false , error: error, show: true});
    }
  }

  getBackendMarkers = async () => {
    try {
      const res = await this.toggleLoading(
        InciInstance.get(`incident/${this.props.incidentId}/`, this.getAuthHeader())
      );
      return res.data.data.markers;
    } catch (error) {
      console.log("Get Incident by ID API error (Marker)");
      // this.setState({ error: error, show: true})
    }
  };

  renderBackendMarkers = (markerInfo) => {
    markerInfo.then((markerInfo) => {
      this.markersString = JSON.parse(markerInfo);
      this.markers = Object.assign(
        {},
        ...Object.keys(this.markersString).map((k) => ({ [k]: JSON.parse(this.markersString[k]) }))
      );
      for (let key in this.markers) {
        let marker = new window.google.maps.Marker({
          position: this.markers[key].position,
          icon: this.markers[key].icon,
        });
        marker.addListener("rightclick", () => {
          this.deleteMarker(marker, key);
        });
        marker.setMap(this.map);
        this.markerArray.push(marker);
      }
      this.markerApiResponse = markerInfo;
    });
  };

  // Marker options based on the button pressed
  chooseMarker = (markerOption) => {
    const anchor = new window.google.maps.Point(17, 17);
    // const imgDir = (filename) => `../../../../assets/images/${filename}`;
    this.drawingManager.setOptions({
      drawingMode: "marker",
    });
    switch (markerOption) {
      case "Helicopter":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/helipadIcon.png"), anchor: anchor },
          },
        });
        break;
      case "Command":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/commandIcon.png"), anchor: anchor },
          },
        });
        break;
      case "Access":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/accessIcon.png"), anchor: anchor },
          },
        });
        break;
      case "Medical":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/medicalIcon.png"), anchor: anchor },
          },
        });
        break;
      case "Patient":
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/patientIcon.png"), anchor: anchor },
          },
        });
        break;
      default:
        this.drawingManager.setOptions({
          markerOptions: {
            icon: { url: require("../../../../../assets/images/dangerIcon.png"), anchor: anchor },
          },
        });
        break;
    }
  };

  onMarkerComplete = async (marker) => {
    let markerObj = {
      position: marker.getPosition(),
      icon: marker.getIcon(),
    };

    let key = Date.now();
    this.markers[key] = markerObj;

    let markerObjStr = JSON.stringify(markerObj);
    this.markersString[key] = markerObjStr;

    marker.addListener("rightclick", () => {
      this.deleteMarker(marker, key);
    });

    this.markerArray.push(marker);

    try {
      await this.toggleLoading(
        InciInstance.post(
          `incident/${this.props.incidentId}/add-markers/`,
          { markerInfo: this.markersString },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Add markers API error");
      // this.setState({ error: error, show: true})
    }
  };

  deleteMarker = async (marker, key) => {
    marker.setMap(null);
    try {
      await this.toggleLoading(
        InciInstance.delete(
          `incident/${this.props.incidentId}/delete-markers/`,
          { data: { markerInfo: { [key]: this.markersString[key] } } },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Delete markers API error");
      // this.setState({ error: error, show: true})
    }
  };

  attachDrawingManager = () => {
    //  Construct the drawing manager
    this.drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON,
          window.google.maps.drawing.OverlayType.MARKER,
        ],
      },
      polygonOptions: {
        fillColor: "#67D615",
        fillOpacity: 0.3,
        strokeWeight: 2,
        strokeColor: "#36B9FA",
        zIndex: 1,
      },
      markerOptions: {
        icon: {
          url: require("../../../../../assets/images/dangerIcon.png"),
          anchor: new window.google.maps.Point(17, 17),
        },
      },
    });
    this.drawingManager.addListener("markercomplete", (marker) => {
      this.onMarkerComplete(marker);
    });
    this.drawingManager.addListener("polygoncomplete", (polygon) => {
      this.onPolygonComplete(polygon);
    });

    //  Attach the drawing manager on the map
    this.drawingManager.setMap(this.map);
  };

  attachGooglePlaces = () => {
    let hospitalRequest = {
      location: this.mapCenter,
      radius: "2000",
      type: ["hospital"],
    };

    this.service = new window.google.maps.places.PlacesService(this.map);

    this.service.nearbySearch(hospitalRequest, callback);

    const createMarker = (place) => {
      let marker = new window.google.maps.Marker({
        map: this.map,
        position: place.geometry.location,
      });
      let content = `<h6>${place.name}</h6>` + `<br><b>Address</b>: ${place.vicinity}`;
      let infowindow = new window.google.maps.InfoWindow();
      window.google.maps.event.addListener(marker, "mouseover", function () {
        infowindow.setContent(content);
        infowindow.open(this.map, marker);
      });
      window.google.maps.event.addListener(marker, "mouseout", function () {
        infowindow.close();
      });

      navigator.geolocation.watchPosition((position) => {
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);

      let marker1 = new window.google.maps.Marker({
        map: this.map,
        position: {lat: position.coords.latitude , lng: position.coords.longitude }
        // position: {lat: 37.9939136 , lng: 23.678646999999998 }
      });
      console.log(this.props)
      let content1 = `<h6>I am here </h6>` + `<b>${this.props.security.user.user_roles[0]}</b>`;
      window.google.maps.event.addListener(marker1, "mouseover", function () {
        infowindow.setContent(content1);
        infowindow.open(this.map, marker1);
      });
      window.google.maps.event.addListener(marker1, "mouseout", function () {
        infowindow.close();
      });
      })
    };

    function callback(results, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        for (let i = 0; i < results.length; i++) {
          createMarker(results[i]);
        }
      }
    }
  };

  mapConstructor = async () => {
    const mapData = await InciInstance.get(`incident/${this.props.incidentId}/`);
    this.mapCenter = new window.google.maps.LatLng(
      Number(mapData.data.data.lat),
      Number(mapData.data.data.lng)
    );
    // Construct the map
    this.map = new window.google.maps.Map(document.getElementById("OfficialMap"), {
      center: {
        lat: Number(mapData.data.data.lat),
        lng: Number(mapData.data.data.lng),
      },
      zoom: 13,
    });
    this.attachDrawingManager();
    this.attachGooglePlaces();
  };

  loadGoogleMaps = () => {
    if (!window.google) {
      let googleMapsScript = document.createElement("script");
      googleMapsScript.type = "text/javascript";
      googleMapsScript.src = `https://maps.google.com/maps/api/js?key=${GoogleAPIKey}&v=3.exp&libraries=geometry,drawing,places`;
      let scriptNode = document.getElementsByTagName("script")[0];
      scriptNode.parentNode.insertBefore(googleMapsScript, scriptNode);

      // Below is important.
      // We cannot access google.maps until it's finished loading
      googleMapsScript.addEventListener("load", (e) => {
        this.mapConstructor();
      });
    } else {
      this.mapConstructor();
    }
  };

  componentDidMount = async () => {
    await this.loadGoogleMaps();
    this.polygonTimer = setTimeout(() => {
      this.renderBackendPolygons(this.getBackendPolygons());
    }, 1000);
    this.markerTimer = setTimeout(() => {
      this.renderBackendMarkers(this.getBackendMarkers());
    }, 1000);
    this.intervalTimer = setInterval(() => {
      this.checkForChanges();
    }, 20000);
  };

  componentWillUnmount() {
    clearTimeout(this.polygonTimer);
    clearTimeout(this.markerTimer);
    clearTimeout(this.intervalTimer);
  }

  checkForChanges = () => {
    let latestMarkerApiRes = this.getBackendMarkers();
    latestMarkerApiRes.then((markersResolved) => {
      if (!_.isEqual(this.markerApiResponse, markersResolved)) {
        for (let marker of this.markerArray) {
          try {
            marker.setMap(null);
          } catch (error) {
            console.log("Marker does not exist!");
            this.setState({ error: error, show: true})
          }
        }
        this.markerArray = [];
        this.renderBackendMarkers(latestMarkerApiRes);
      }
    });

    let latestPolygonApiRes = this.getBackendPolygons();
    latestPolygonApiRes.then((polygonsResolved) => {
      if (!_.isEqual(this.polygonApiResponse, polygonsResolved)) {
        for (let polygon of this.polygonArray) {
          try {
            polygon.setMap(null);
          } catch (error) {
            console.log("Polygon does not exist!");
            this.setState({ error: error, show: true})
          }
        }
        this.polygonArray = [];
        this.renderBackendPolygons(latestPolygonApiRes);
      }
    });
  };

  // Polygon options based on the button pressed
  colorOptions = (color) => {
    const common = {
      fillOpacity: 0.3,
      strokeWeight: 2,
      strokeColor: "#36B9FA",
      zIndex: 1,
    };
    this.drawingManager.setOptions({
      drawingMode: "polygon",
    });
    switch (color) {
      case "safe":
        this.drawingManager.setOptions({ polygonOptions: { fillColor: "#67D615", ...common } });
        break;
      case "caution":
        this.drawingManager.setOptions({ polygonOptions: { fillColor: "#F5D316", ...common } });
        break;
      default:
        this.drawingManager.setOptions({ polygonOptions: { fillColor: "#FF0000", ...common } });
        break;
    }
  };

  onPolygonComplete = async (polygon) => {
    let polygonObj = {
      path: polygon.getPath().Be,
      fillColor: polygon.fillColor,
      fillOpacity: polygon.fillOpacity,
      strokeWeight: polygon.strokeWeight,
      strokeColor: polygon.strokeColor,
      zIndex: polygon.zIndex,
    };
    let key = Date.now();
    this.polygons[key] = polygonObj;

    let polygonObjStr = JSON.stringify(polygonObj);
    this.polygonsString[key] = polygonObjStr;

    polygon.addListener("rightclick", () => {
      this.deletePolygon(polygon, key);
    });

    polygon.addListener("mouseup", () => {
      this.resizePolygon(polygon, key);
    });

    this.polygonArray.push(polygon);

    try {
      await this.toggleLoading(
        InciInstance.post(
          `incident/${this.props.incidentId}/add-polygons/`,
          { polygonInfo: this.polygonsString },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Add polygons API error");
      // this.setState({ error: error, show: true})
    }
  };

  getBackendPolygons = async () => {
    try {
      const res = await this.toggleLoading(
        InciInstance.get(`incident/${this.props.incidentId}/`, this.getAuthHeader())
      );
      return res.data.data.polygons;
    } catch (error) {
      console.log("Get Incident by ID API error (Polygon)");
      // this.setState({ error: error, show: true})
    }
  };

  renderBackendPolygons = (polygonInfo) => {
    polygonInfo.then((polygonInfo) => {
      // If we get invalid JSON, for whatever reason, then avoid crashing.
      try {
        this.polygonsString = JSON.parse(polygonInfo);
      } 
      catch {
        polygonInfo = "{}";
        this.polygonsString = JSON.parse(polygonInfo);
      }
      
      this.polygons = Object.assign(
        {},
        ...Object.keys(this.polygonsString).map((k) => ({
          [k]: JSON.parse(this.polygonsString[k]),
        }))
      );

      for (let key in this.polygons) {
        let polygon = new window.google.maps.Polygon({
          editable: true,
          fillColor: this.polygons[key].fillColor,
          fillOpacity: this.polygons[key].fillOpacity,
          strokeWeight: this.polygons[key].strokeWeight,
          strokeColor: this.polygons[key].strokeColor,
          zIndex: this.polygons[key].zIndex,
        });
        polygon.setPath(this.polygons[key].path);
        polygon.addListener("rightclick", () => {
          this.deletePolygon(polygon, key);
        });
        polygon.addListener("mouseup", () => {
          this.resizePolygon(polygon, key);
        });
        polygon.setMap(this.map);
        this.polygonArray.push(polygon);
      }
      this.polygonApiResponse = polygonInfo;
    });
  };

  resizePolygon = async (polygon, key) => {
    // We need to extract out polygon coordinate manually
    // because otherwise there will be a
    // "Converting circular structure to JSON" error.
    let coordinates = [];
    polygon
      .getPath()
      .getArray()
      .forEach((i) => {
        coordinates.push({ lat: i.lat(), lng: i.lng() });
      });

    // The gm_accessors_ and gm_bindings_ lines are here so that
    // the custom polygon matches other polygons.
    const paths = {
      i: coordinates,
      gm_accessors_: { length: null },
      length: coordinates.length,
      gm_bindings_: { length: {} },
    };

    let updatedPolygon = {
      paths,
      fillColor: polygon.fillColor,
      fillOpacity: polygon.fillOpacity,
      strokeWeight: polygon.strokeWeight,
      strokeColor: polygon.strokeColor,
      zIndex: polygon.zIndex,
    };

    this.polygons[key] = updatedPolygon;
    let updatedPolygonStr = JSON.stringify(updatedPolygon);
    this.polygonsString[key] = updatedPolygonStr;

    try {
      await this.toggleLoading(
        InciInstance.put(
          `incident/${this.props.incidentId}/update-polygons/`,
          { polygons: JSON.stringify(this.polygonsString) },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Update polygons API error");
      // this.setState({ error: error, show: true})
    }
  };

  deletePolygon = async (polygon, key) => {
    polygon.setMap(null);
    try {
      await this.toggleLoading(
        InciInstance.delete(
          `incident/${this.props.incidentId}/delete-polygons/`,
          { data: { polygonInfo: { [key]: this.polygonsString[key] } } },
          this.getAuthHeader()
        )
      );
    } catch (error) {
      console.log("Delete polygons API error");
      // this.setState({ error: error, show: true})
    }
  };

  render() {
    return (
      <div id="cisMapContainer">
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col text-center">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic-polygon" size="sm">
                    Area Colors
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {this.polygonColorOptions.map((color) => (
                      <Dropdown.Item
                        id={`dropdown-item-${color}`}
                        key={color}
                        onClick={() => this.colorOptions(color)}
                      >
                        {color.toUpperCase()}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col text-center">
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic-marker" size="sm">
                    Marker Options
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {this.markerOptions.map((marker) => (
                      <Dropdown.Item key={marker} onClick={() => this.chooseMarker(marker)}>
                        {marker.toUpperCase()}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {this.state.loading === true ? (
              <div id="cisMapModalContainer">
                <div id="CisMapModal">
                  <Spinner />
                </div>
              </div>
            ) : (
              <div />
            )}
            <div id="OfficialMap"/>           
            
          </div>
        </div>
      </div>
    );
  }
}

// export default CisMapOfficial;
const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps)(CisMapOfficial);