import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { updateUser } from "../../../Redux/actions/Users/securityActions";
import { Link } from "react-router-dom";
import { InciInstance, UserInstance } from "../../../config";
import { connect } from "react-redux";
import CisMapOfficial from "./CisComponents/Map/CisMapOfficial";
import Weather from "./CisComponents/Weather";
import RequestDispatchModal from "./CisComponents/RequestDispatchModal";
import ChatApp from "../../../components/Chat/ChatApp";
import {
  showDispatchModal,
  hideDispatchModal,
} from "../../../Redux/actions/UI/toggleDispatchModalAction";

import ErrorModal from "../../../components/UI/Modal/ErrorModal";
import DSS from './CisComponents/DSS/DSS'

class Cis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrivedButton: false,
      updated: false,
      showChatModal: false,
      show: false,
      error: {},
    };
  }

  incidentData = null;
  incidentId = null;

  getIncidentData = async () => {
    // if (this.props.location.prePopulate) {
    //   this.incidentId = this.props.location.prePopulate.id;
    //   this.incidentData = this.props.location.prePopulate;
    //   this.setState({ updated: !this.state.updated });
    //   if (this.props.location.hasArrived !== "undefined") {
    //     this.setState({ arrivedButton: this.props.location.hasArrived });
    //   }
    // } else {
    // let params = new URLSearchParams(window.location.search);
    // let id = params.get("id");

    //OLD INCIDENT ID CLARIFICATION

    // let id = this.props.selectedIncident.id;
    // this.incidentId = id;
    // const res = await InciInstance.get(`incident/${id}/`);
    // this.incidentData = res.data.data;
    // console.log("data", this.incidentData);
    // this.setState({ updated: !this.state.updated });

    // NEW INCIDENT ID CLARIFICATION

    if (this.props.security.user.incidentId && this.props.security.user.incidentId !== undefined) {
      let id = this.props.security.user.incidentId;
      this.incidentId = id;
      // const res = await InciInstance.get(`incident/${id}/`);

      let sitrep = await InciInstance.get(`/incident/${id}/`);

      this.incidentData = sitrep.data.data;
      this.setState({ updated: !this.state.updated });
    }

    // }
  };

  checkArrival = async () => {
    const authentication = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    };
    if (this.props.userRole !== "PSAP") {
      try {
        const arrivedStatus = await InciInstance.get(
          `incident/${this.incidentId}/`,
          authentication
        );
        for (let i = 0; i < arrivedStatus.data.data.dispatched_users.length; i++) {
          if (arrivedStatus.data.data.dispatched_users[i] === this.props.userId) {
            try {
              const arrivedUser = await UserInstance.get(
                `user/${this.props.userId}/`,
                authentication
              );
              this.setState({
                arrivedButton: arrivedUser.data.data.details.incident_details[0].hasArrived,
              });
            } catch (error) {
              console.log("Arrival Identification Issue");
              // this.setState({error: error, show: true})
            }
          }
        }
      } catch (error) {
        console.log("Get actor info API error");
        // this.setState({error: error, show: true});
      }
    }
  };

  componentDidMount = async () => {
    await this.getIncidentData();
    this.checkArrival();
  };

  clickArrivedButton = async (e) => {
    e.preventDefault();
    if (this.props.userRole !== "PSAP") {
      const authentication = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      };
      try {
        await InciInstance.put(
          `incident/${this.incidentId}/${this.props.userId}/arrival/`,
          authentication
        );
        this.props.updateUser(localStorage.getItem("jwtRefresh"));
        console.log(this.props)
        this.setState({ arrivedButton: !this.state.arrivedButton });
      } catch (error) {
        console.log("Arrival API error");
        this.setState({error: error, show: true})
      }
    }
  };

  onHandleDispatchModalShow = () => {
    this.setState({ dispatchModalShow: true });
  };

  renderButtonDependingOnRole = () => {
    console.log(this.props)
    switch (this.props.security.user.incidentRole) {
      case "PSAP":
        return (
          <>
          <button
            className="btn  btn-sm"
            id="request-troops-button"
            onClick={() => {
              this.props.showDispatchModal();
            }}
          >
            Request Troops
          </button>
          <Link
          to={{
            pathname: "/Sitrep",
          }}
        >
          <button className="btn  btn-sm" id="request-troops-button">
            SitRep
          </button>
        </Link>
        </>
        );

      case "FIELD_COMMANDER":
        return (
          <Link
            to={{
              pathname: "/Sitrep",
            }}
          >
            <button className="btn  btn-sm" id="request-troops-button">
              SitRep
            </button>
          </Link>
        );

      default:
        break;
    }
    console.log('renderdbutton',this.props)
  };

  handleChatModal = (e) => {
    this.setState({ showChatModal: true });
  };


  handleClose = () => {
    this.setState({show: false});
  };

  statusHandler = async () => {
      await InciInstance.get(`/incident/${this.incidentId}/status/`);
      this.props.history.push("/Logout");

  };
  
  logoutNullIncident = () => { 
       setTimeout(() => {
        this.props.history.push("/Logout")
    }, 4000)
  }

  render() {
    // const alert = this.props.alert;
    return (
      <>
      {!this.props.security.user.incidentId ? 
      (  <h2 style={{color:"#065097"}}>  Υou are not assigned to an incident  {this.logoutNullIncident()} </h2>): (
        <div>
        <div className="cis-grid-container">
          <div className="cis-Incident-Info">
                   
            <h3 className="h3-psap-style">Common Incident Space
            {this.props.security.user.incidentRole === "PSAP" ? <><button type="button" className="btn btn-primary btn-sm mr-0" id="active-button-psap"  disabled>Active</button>
                <button type="button" className="btn btn-light btn-sm ml-0"   onClick={() => { if (window.confirm('This action will delete the incident for all users !  Are you sure ?')) (this.statusHandler() )  } }>Inactive</button></> : null }
            </h3>
            <h4 className="h4-psap-style">
      
              <span className="incident-id"> INCIDENT ID: {this.incidentId} </span> (MAJOR INCIDENT
              ALERT/STANDBY)
  
              {this.renderButtonDependingOnRole()}
              <RequestDispatchModal
                show={this.props.dispatchModal}
                isClosed={() => this.props.hideDispatchModal()}
                incidentId={this.incidentId}
              />
               <ErrorModal
                  onHide={this.handleClose}
                  handleClose={this.handleClose}
                  show  ={this.state.show}
                  error = {this.state.error}
              />
              <button
                className="btn  btn-sm"
                id="request-troops-button"
                onClick={() => {
                  this.handleChatModal();
                }}
              >
                Chat
              </button>
              <ChatApp
                show={this.state.showChatModal}
                isClosed={() => this.setState({ showChatModal: false })}
              />
            </h4>
  
            {this.incidentData !== null ? (
              <div className="psap-screen-style">
                <ul>
                  <li>
                    <h5 className="h5-psap-style">Emergency type</h5>
                    {this.incidentData.typeOfEmergency}
                  </li>
                  {/* <li>
                    <h5 className="h5-psap-style">Hazard type</h5>
                    {this.incidentData.hazardBackground}
                  </li> */}
                  <li>
                    <h5 className="h5-psap-style">Human Casualties</h5>
                    {this.incidentData.numberOfAdultPatients}
                  </li>
                  <li>
                    <h5 className="h5-psap-style">Additional Information</h5>
                    {this.incidentData.additionalInformation}
                  </li>
                </ul>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="cis-Weather">
            {this.incidentData !== null ? (
              <Weather lat={this.incidentData.lat} lng={this.incidentData.lng} />
            ) : null}
          </div>
          <div className="cis-Map-CIS">
            {!this.state.arrivedButton ? <div className="backdrop-cis" /> : null}
            {this.incidentId !== null ? <CisMapOfficial 
            incidentId={this.incidentId} /> : <div></div>}
            <div className="arrived-button">
              {this.state.arrivedButton === true ? (
                <button className="btn btn-success btn-lg" style={{ display: "none" }}>
                  Arrived On Site
                </button>
              ) : (
                <button className="button-arrived-yellow" onClick={this.clickArrivedButton}>
                  ARRIVED ON SITE
                </button>
              )}
            </div>
          </div>
              <div className="cis-DSS-CIS">{this.incidentId!==null && this.props.security.user.incidentRole === "PSAP" ? <DSS incidentId={this.incidentId}/>: null}</div>
        </div>
        </div> 
      )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  security: state.security,
  userId: state.security.user.user_id,
  userRole: state.security.user.user_roles[0],
  selectedIncident: state.selectIncident,
  dispatchModal: state.toggleDispatchModal.showDispatch,
  incidents: state.incidents,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showDispatchModal,
      hideDispatchModal,
      updateUser,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cis);
