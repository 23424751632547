const environment = {
  mainUrl: 'http://167.71.60.27:2020/api/',
  wsUrl: 'ws://167.71.60.27:2020',
}

//DEV
// if (process.env.REACT_APP_ENV === 'development') {
//   environment.mainUrl = 'http://167.71.60.27:2020/api/'
// }

//PRODUCTION
switch (process.env.REACT_APP_ENV) {
  case 'staging':
    environment.mainUrl = 'http://167.71.60.27:2020/api/'
    environment.wsUrl = 'ws://167.71.60.27:2020'
    break
  case 'demo_magma':
    environment.mainUrl = 'https://democoncorde.konnekt-able.com:8443/api/'
    environment.wsUrl = 'wss://democoncorde.konnekt-able.com:8443'
    break
  default:
    environment.mainUrl = 'http://localhost:8000/api/'
    environment.wsUrl = 'ws://localhost:8000'
    break
}

export default environment
