import axios from 'axios'
import environment from './environment'

//Digital ocean droplets IPs
export const authInstance = axios.create({
  baseURL: environment.mainUrl,
})
authInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwtToken')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export const OrgInstance = axios.create({
  baseURL: `${environment.mainUrl}` + 'organizations/',
})
OrgInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwtToken')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})
export const UserInstance = axios.create({
  baseURL: `${environment.mainUrl}` + 'users/',
})
UserInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwtToken')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export const InciInstance = axios.create({
  baseURL: `${environment.mainUrl}` + 'incidents/',
})
InciInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwtToken')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})
export const ChatHistoryInstance = axios.create({
  baseURL: `${environment.mainUrl}` + 'chat/',
})
ChatHistoryInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwtToken')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})
export const AnalInstance = axios.create({
  baseURL: 'http://165.22.77.92:2020/analytics',
})

export const AnalAPIKey = '6f249b9e850b8a513643f33ad75ba37b'

export const GoogleAPIKey = 'AIzaSyDyblmvy9IiY7HBSdZ6e45gJMIf3I85cwQ'

// // // Locali
// export const authInstance = axios.create({
//   baseURL: "http://192.168.2.19:9100/api/v1"
// });

// export const OrgInstance = axios.create({
//   baseURL: "http://192.168.2.19:8200/api/v1"
// });

// export const InciInstance = axios.create({
//   baseURL: "http://192.168.2.19:8300/api/v1"
// });

// ----TRIAGE-----

export const TriageInstance = axios.create({
  baseURL: `${environment.mainUrl}` + 'triage',
})
TriageInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('jwtToken')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})
